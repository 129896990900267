import FreemiumBannerDesktopComponent from '@wix/thunderbolt-elements/src/components/FreemiumBanner/FreemiumBannerDesktop/viewer/FreemiumBannerDesktop';


const FreemiumBannerDesktop = {
  component: FreemiumBannerDesktopComponent
};


export const components = {
  ['FreemiumBannerDesktop']: FreemiumBannerDesktop
};

